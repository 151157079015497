<template>
  <div>
    <div class="d-flex-row">
      <div
        class="date-item all-dates"
        :class="{ active: -1 == currentIndex }"
        @click="getList(null, -1)"
      >
        <span>全部</span><br />
        <span>日期</span>
      </div>
      <div
        class="date-item single-dates"
        :class="{ active: index == currentIndex }"
        v-for="(item, index) in dateList"
        :key="index"
        @click="getList(item, index)"
      >
        <span>{{ item.title }}</span
        ><br />
        <span>{{ item.date }}</span>
      </div>
    </div>
    <div class="doctor-cards" v-for="(item, index) in doctorList" :key="index">
      <div class="doctor-card d-flex-column">
        <div class="d-flex-row doctor-info">
          <div>
            <img
              src="@/assets/img/yisheng.png"
              v-if="item.doctorPhoto == null || item.doctorPhoto == ''"
              class="doctor-img"
              alt=""
            />
            <img :src="item.doctorPhoto" v-else class="doctor-img" alt="" />
          </div>
          <div class="d-flex-column d-flex-left doctor-desc">
            <div style="text-align: left">
              <span style="font-size: 0.42rem">{{ item.bizDoctorName }}</span>
              <span style="margin-left: 0.4rem">{{ item.doctorLevel }}</span>
            </div>
            <div>
              <span style="font-size: 0.4rem">{{
                item.bizDeptName.replace(item.bizDoctorName, "")
              }}</span>
              <span style="margin-left: 0.5rem; color: #afafaf"
                >已预约量：<b style="color: #2ea5ff">{{
                  item.regTotalNum
                }}</b></span
              >
            </div>
          </div>
        </div>
        <div class="order-view">
          <van-cell-group>
            <div v-for="(v, i) in item.details" :key="i" :index="i">
              <van-cell
                :title="formatTitle(v.dutyDate, v.dutyWeekDay, v.dutyTimeFlag)"
                v-if="item.details.length != 0"
                @click="selectDoctor(v, item)"
                is-link
                :value="'余' + v.dutyRemainedNum"
                value-class="order-title"
                style="text-align: left"
              />
              <van-cell
                v-else
                :title="formatTitle(v.dutyDate, v.dutyWeekDay, v.dutyTimeFlag)"
                value="暂未开放"
                is-link
                style="text-align: left"
              />
            </div>
            <div class="more-area" @click="showMore(item)">
              <div class="more-content">查看更多</div>
            </div>
          </van-cell-group>
        </div>
      </div>
    </div>
    <div class="zt-nodata" v-show="isEmpty">暂无排班医生</div>
    <van-popup v-model="showPopup" position="bottom">
      <van-cell-group>
        <div v-for="(v, i) in dateArray" :key="i" :index="i">
          <van-cell
            :title="formatTitle(v.dutyDate, v.dutyWeekDay, v.dutyTimeFlag)"
            is-link
            @click="selectDoctor(v, currentDoctor)"
            v-if="dateArray.length != 0"
            :value="'余' + v.dutyRemainedNum"
            value-class="order-title"
            style="text-align: left"
          />
          <van-cell
            v-else
            :title="formatTitle(v.dutyDate, v.dutyWeekDay, v.dutyTimeFlag)"
            value="暂未开放"
            is-link
            style="text-align: left"
          />
        </div>
      </van-cell-group>
    </van-popup>
  </div>
</template>

<script>
import moment from "moment"; // 引入moment
import _ from "lodash";
import { Toast } from "vant";

export default {
  data() {
    return {
      dateList: [],
      allData: [],
      doctorList: [],
      dateArray: [],
      showDates: 4,
      currentIndex: -1,
      isEmpty: true,
      showPopup: false,
      labelType: "",
      currentDoctor: {},
      dutyTimeFlag: {
        1: "上午",
        2: "下午",
        3: "晚上",
        4: "白天",
        5: "昼夜",
        6: "小夜",
        7: "夜间",
        8: "午间",
      },
      WeekDay: {
        2: "周一",
        3: "周二",
        4: "周三",
        5: "周四",
        6: "周五",
        7: "周六",
        1: "周日",
      },
      dateEnum: {
        Mon: "周一",
        Tue: "周二",
        Wed: "周三",
        Thu: "周四",
        Fri: "周五",
        Sat: "周六",
        Sun: "周日",
      },
    };
  },
  methods: {
    formatTitle(dutyDate, dutyWeekDay, dutyTimeFlag) {
      return (
        moment(dutyDate).format("MM-DD") +
        "(" +
        this.WeekDay[dutyWeekDay] +
        ")" +
        this.dutyTimeFlag[dutyTimeFlag]
      );
    },
    getList(e, index) {
      const toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "加载中...",
      });
      if (!e) {
        e = {
          startTime: moment(new Date()).format("YYYY-MM-DD"),
          endTime: moment(new Date())
            .add(this.showDates - 1, "days")
            .format("YYYY-MM-DD"),
        };
      }
      if (this.$route.query.type == 0) {
        // 当日预约
        e.startTime = moment(new Date()).format("YYYY-MM-DD");
        e.endTime = moment(new Date()).format("YYYY-MM-DD");
      } else {
        // 预约挂号
        if (index != -1) {
          // 不是全部
          e.startTime = moment(new Date())
            .add(index + 1, "days")
            .format("YYYY-MM-DD");
          e.endTime = moment(new Date())
            .add(index + 1, "days")
            .format("YYYY-MM-DD");
        } else {
          e.startTime = moment(new Date()).add(1, "days").format("YYYY-MM-DD");
          e.endTime = moment(new Date()).add(3, "days").format("YYYY-MM-DD");
        }
      }
      this.currentIndex = index;
      this.doctorList = [];
      let postData = {
        deptCode: this.$route.query.deptCode,
        beginDate: e.startTime,
        endDate: e.endTime,
        LabelType: this.$route.query.LabelType,
        type: this.$route.query.type,
      };
      this.$http
        .get("/api/wlptappointment/eh3104", postData)
        .then((res) => {
          Toast.clear();
          if (res.status === 200) {
            this.doctorList = res.data;
            this.isEmpty = this.doctorList.length == 0;
          }
        })
        .catch((err) => {
          Toast.clear();
          Toast({ message: err.response.data });
        });
    },
    showMore(doctor) {
      this.currentDoctor = doctor;
      this.dateArray = doctor.details;
      this.showPopup = true;
    },
    selectDoctor(data, doctor) {
      let clinicDateText =
        moment(data.dutyDate).format("MM-DD") +
        "(" +
        this.WeekDay[data.dutyWeekDay] +
        ")" +
        this.dutyTimeFlag[data.dutyTimeFlag];
      this.$router.push({
        path: "/appointSelect",
        query: {
          data: encodeURIComponent(JSON.stringify(data)),
          doctor: encodeURIComponent(JSON.stringify(doctor)),
          clinicDateText: clinicDateText,
          LabelType: this.$route.query.LabelType,
          type: this.$route.query.type,
          covidorderid: this.$route.query.id,
        },
      });
    },
  },
  mounted() {
    this.LabelType = this.$route.query.LabelType;
    let date = new Date();
    this.dateList.push({
      startTime: moment(date).format("YYYY-MM-DD"),
      endTime: moment(date).format("YYYY-MM-DD"),
      date: moment(date).format("MM-DD"),
      title: "今天",
    });
    for (let i = 1; i < this.showDates; i++) {
      date = moment(date).add(1, "days");
      this.dateList.push({
        startTime: moment(date).format("YYYY-MM-DD"),
        endTime: moment(date).format("YYYY-MM-DD"),
        date: moment(date).format("MM-DD"),
        title: this.dateEnum[moment(date).format("ddd")],
      });
    }
    if (this.$route.query.type == 0) {
      //当日预约
      this.dateList.length = 1;
    } else {
      //预约挂号
      this.dateList.shift();
    }
    this.getList(null, -1);
  },
};
</script>

<style scoped>
.date-item {
  margin: 5px 0px 5px 5px;
  padding: 8px;
  height: 1.5rem;
  line-height: 0.5rem;
  border-radius: 5px;
  color: #afafaf;
  background-color: #f5f5f7;
  border: 1px solid #f5f5f7;
}

.date-item.active {
  background-color: #dbf9ef;
  border: solid 1px #1ad997;
  color: #1ad997;
}

.all-dates {
  background-color: #f5f5f7;
  margin-right: 5px;
}

.all-dates > .date-item {
  margin-right: 5px;
  margin-left: 10px;
}

.d-flex-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 0.4rem;
  background-color: #fff;
}

/* 医生列表 */
.doctor-cards {
  padding: 2px 10px 2px 10px;
}

.doctor-card {
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.doctor-info {
  padding-left: 10px;
}

.doctor-desc {
  margin-left: 5px;
}

.order-title {
  color: #52e2b1;
}

.more-content {
  text-align: center;
  color: #1ad997;
  font-size: 0.4rem;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* 暂无数据提示样式 */
.zt-nodata {
  margin-top: 50%;
  color: #888;
  text-align: center;
  font-size: 0.4rem;
}

.doctor-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
</style>
